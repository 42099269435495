import React, { useEffect, useState } from 'react'
import '../../../Assets/style/Basic-component-styles/dashboard/leaderboard.css'
import Scoreboard from '../../AG-charts/Scoreboard'
import { quizResut} from "../../Api/online-test-fetch-data";



function Leaderboard(props) {

    const [leaderboard_data, setleaderboard_data] = useState([])
    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {

                const apiData = await quizResut("userID");
                setleaderboard_data(apiData);

            } catch (error) {
                // Handle error if needed
                console.log(error);
            }
        };

        fetchDataFromApi();
    }, []);


    const [scoreSlicer, setScoreSlicer] = useState(6)
   
    return (
        <div className='leaderboard'>
            <div className="leaderboard-user-overview">
               
                <div className="user-overview-chart">
                    <Scoreboard data={leaderboard_data}/>
                </div>
            </div>
            
        </div>
    )
}

export default Leaderboard