// load server url
import endpoints from './endpoints.json';



// get contest data
export const fetchOnline_Test = async (contests, contestType = "") => {
  console.log(contests);
  try {
    const response = await fetch(
      `${endpoints.server1}/contests/${contests}/${contestType}`,
      {
        method: "GET",
      }
    );
    const data = await response.json();

    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const postQuiz = async (userId, contestId, question, user_ans) => {
  try {
    
      const response = await fetch(
      `${endpoints.server1}/contests/quiz-contest-submition/${userId}/${contestId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify({ question: question, user_ans: user_ans }),
      }
    );

    const data = await response.json();
    if (data.Response) {
      console.log(data.Response.status);
      if (data.Response.status === 200) {
        var userDataString={ question: question, user_ans: user_ans }
        localStorage.setItem(question,JSON.stringify( userDataString));
        return question
      }
    }

    
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};




// --------------------------
export const quizResut = async (userId) => {
  console.log(userId);
  try {
    const response = await fetch(
      `${endpoints.server1}/contests/get-result`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify({user:userId }),

      }
    );
    const data = await response.json();

    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
