import React, { useEffect, useState } from "react";
import CodeEditor from "../Basic-components/contest-components/CodeEditor";
// import "../../Assets/style/pages-styles/Hackthon.css";
import { fetchOnline_Test } from "../Api/online-test-fetch-data";
import { useLocation } from "react-router-dom";
export default function Hackthon() {
  const[queLevel ,setqueLevel]=useState( localStorage.getItem("currentLevel") ?? 2 )
  const [test_data, setTest_data] = useState("");
  const [question_index, setquestion_index] = useState(0);
  const [levelsCleared] = useState(new Set());

  const [isloading_data, setloading_data] = useState(true);
  // editor
  const [editorValue, setEditorValue] = useState("");
  const l = useLocation()
  const handleEditorChange = (value) => {
    setEditorValue(value);
  };
  // code Output
  const [Codeoutput, setCodeoutput] = useState({ output: {} });
  // timer-------------

  const initialTime = JSON.parse(localStorage.getItem("countdownTime")) || {
    hours: 1,
    minutes: 30,
    seconds: 0,
  };
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    console.log("aaaaaaaaa", l);
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime.seconds === 0) {
          if (prevTime.minutes === 0) {
            if (prevTime.hours === 0) {
              clearInterval(timer);
              return prevTime;
            } else {
              return { hours: prevTime.hours - 1, minutes: 59, seconds: 59 };
            }
          } else {
            return { ...prevTime, minutes: prevTime.minutes - 1, seconds: 59 };
          }
        } else {
          return { ...prevTime, seconds: prevTime.seconds - 1 };
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    localStorage.setItem("countdownTime", JSON.stringify(time));
  }, [time]);

  //----------------

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
       
        const apiData = await fetchOnline_Test(queLevel);
        setTest_data(apiData);
        setTimeout(() => {
          setloading_data(false);
        }, 1);
      } catch (error) {
        // Handle error if needed
        console.log(error);
      }
    };

    fetchDataFromApi();
  }, [queLevel]);

  const next = async () => {
    if (question_index < test_data.length - 1) {
      setquestion_index(question_index + 1);
      setEditorValue("");
      setCodeoutput({ output: {} });
    } else {
      setquestion_index(question_index);
    }
    
  };
  const prev = async () => {
    if (question_index <= test_data.length - 1 && question_index !== 0) {
      setquestion_index(question_index - 1);
      setEditorValue("");
      setCodeoutput({ output: {} });
    } else {
      setquestion_index(question_index);
    }
   
  };

  const submit_answer = async () => {
    // if (question_index < test_data.length - 1) {
    //   setquestion_index(question_index + 1);
    // } else {
    //   console.log(question_index < test_data.length);
    //   setquestion_index(question_index);
    // }
  };
  

  return (
    <>
      <div style={{ width: "90%", margin: "auto" }}>
        {/* editor area */}
        {isloading_data ? (
          <div className="Preloader-hackthon-container">
            <div className="centered-div">
              <div className="test-generatloader"></div>
              <h6>PractCode: Crafting Your Tests with Ease!</h6>
            </div>
          </div>
        ) : (
          <div>
            <div className="header-div">
              <h3>Hackfest - 2024</h3>
              <div className="level-switcher">
                <button style={{background:"#1ba94c"}}>Level 1  <i className="fa-solid fa-check-double"></i></button>
                <button style={{background:"yellow"}}>
                  Level 2 
                </button>
                <button>
                  Level 3 <i className="fa-solid fa-lock"></i>
                </button>
              </div>
              <div className="header-Timer">
                <b>
                  {`${time.hours.toString().padStart(2, "0")}h : ${time.minutes
                    .toString()
                    .padStart(2, "0")}m :  ${time.seconds
                    .toString()
                    .padStart(2, "0")}s `}
                </b>
              </div>
            </div>
            <br />
            <br />

            <CodeEditor
              level={queLevel}
              setqueLevel={setqueLevel}
              editorValue={editorValue}
              
              editorhandler={handleEditorChange}
              Codeoutput={Codeoutput}
              setCodeoutput={setCodeoutput}
              queSet={[question_index, test_data.length]}
              id={test_data[question_index]["_id"]}
              submit={submit_answer}
              next_que={next}
              prev_que={prev}
              levelsCleared={levelsCleared}
              // currentQuestion={}
              question={test_data[question_index]["question"]}
              explaination={test_data[question_index]["explaination"]}
              example={test_data[question_index]["example"]}
            />
          </div>
        )}
      </div>
    </>
  );
}
