import React from "react";
import '../../Assets/style/pages-styles/courses.css'
import courses_img from '../../Assets/image/Cards-img/programming.jpg'
import all_courses_vactor1 from '../../Assets/image/vectors/practcode-all-courses.png'

export default function Courses() {
  return (
    <div className="all-courses-div-class">
        <br/>
        <br/>
      <div className="all-courses-main-top-div">
        <div className="div-section-first">
          <p>Home / courses</p>
          <h2>Courses</h2>
          <p>
            gain hands-on experience through interactive projects, and join a
            thriving learner community.
          </p>
          <div className="all-course-seacrch-box-div">
            <input type="text" placeholder="Search by Course name" />
            <button>Search</button>
            <div className="all-courses-search-filtersdiv">
              Apply Filter <i className="fa-solid fa-arrow-down-wide-short"></i>
            </div>
          </div>
        </div>
        <div className="div-section-second">
          <img
            src={all_courses_vactor1}
            alt=""
          />
        </div>
      </div>
      <br />
      <div className="all-courses-display">
        <br />
        <div className="individual-course-card">
          <div className="card-image">
            <img src={courses_img} alt="" />
          </div>
          <div className="card-details">
            <div className="heading-and-price">
              <div className="heading">Programming</div>
              <span>FREE</span>
            </div>
            <div className="course-title">
              Data science Foundation: Data Structure and Algorithm
            </div>
            <div className="card-other-details">
              <p>
                <i className="fa-solid fa-chart-line"></i>Bignners
              </p>
              <p>
                <i className="fa-solid fa-clock"></i>150 hrs
              </p>
              <p>
                <i className="fa-solid fa-star-half-stroke"></i>4.2(3 reviews)
              </p>
            </div>
            <button>View Course</button>
          </div>
        </div>
        <br />
        <div className="individual-course-card">
          <div className="card-image">
            <img src={courses_img} alt="" />
          </div>
          <div className="card-details">
            <div className="heading-and-price">
              <div className="heading">Programming</div>
              <span>FREE</span>
            </div>
            <div className="course-title">
              Data science Foundation: Data Structure and Algorithm
            </div>
            <div className="card-other-details">
              <p>
                <i className="fa-solid fa-chart-line"></i>Bignners
              </p>
              <p>
                <i className="fa-solid fa-clock"></i>150 hrs
              </p>
              <p>
                <i className="fa-solid fa-star-half-stroke"></i>4.2(3 reviews)
              </p>
            </div>
            <button>View Course</button>
          </div>
        </div>
      </div>
      <br/>
      <br/>
    </div>
  );
}
