import React, { useContext, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Cards from "./Basic-components/Cards";

import Progress_card from "./Basic-components/Progress_card";
import "../Assets/style/home.css";
// <!-- Bootstrap CSS -->
import "../Assets/style/banner-outside-styles/bootstrap.min.css";

import "../Assets/style/banner-outside-styles/animate.min.css";

import "../Assets/style/banner-outside-styles/magnific-popup.css";

import "../Assets/style/banner-outside-styles/style.css";

import "../Assets/style/banner-outside-styles/responsive.css";
// shapes for banner images
import shape1 from "../Assets/image/banner-outside-img/shapes/home-shape.png";
import shape2 from "../Assets/image/banner-outside-img/shapes/home-shape-two.png";
import shape3 from "../Assets/image/banner-outside-img/shapes/home-shape-four.png";
import vector1 from "../Assets/image/vectors/vector.png";

import shape4 from "../Assets/image/banner-outside-img/shapes/greaterthan-equals-shape.png";
import shape5 from "../Assets/image/banner-outside-img/shapes/arrow-shape.png";

import {AuthenticatContext, UserAuthContext } from "../context/AuthenticatContext";
import { NavLink, Navigate } from "react-router-dom";
import ContestCard from "./Basic-components/contest-components/ContestCard";
import { getContest } from './Api/contests-fetch-data'

const Home = () => {
  const { authentication_data, update_authentication } = useContext(AuthenticatContext);
  const { UserAuthdata, update_UserAuthData } = useContext(UserAuthContext)
  // countdown
  useEffect(() => {

    const handleKeyDown = (event) => {
      if (event.ctrlKey) {
        event.preventDefault(); // Prevent default behavior
        // Optionally, you can notify the user that Ctrl key is disabled.
        // alert('Ctrl key is disabled.');
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array ensures that the effect runs only once

  // ----------
  // contest data
  const [contest_data, setContest_data] = useState([])
  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {

        const apiData = await getContest();

        setContest_data(apiData);

      } catch (error) {
        // Handle error if needed
        console.log(error);
      }
    };

    fetchDataFromApi();
  }, []);
  // ------------------------------
  return (
    <>
      {/* user batch */}
      {authentication_data.isLoggedIn? <div className="user-profile-batch">
      <i className="fa-regular fa-user"></i>
        {/* <NavLink to={`/dashboard/${UserAuthdata['userId']}`}>{UserAuthdata['username']}</NavLink> */}
      </div>:<div className="user-profile-batch">
        <NavLink to={"/user/login/"}>login</NavLink>
      </div>}
      {/* ---------- */}
      <div className="home-screen-main-div" >
        {/* <p>{authentication_data.authtoken}</p> */}
        <div id="home" className="main-banner banner-style-four">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="banner-text">
                      <span>Hello</span>
                      <h1>Welcome To Practcode</h1>
                      {/* <h6>online courses</h6> */}
                      <h3>Online courses</h3>

                      <p>
                        🚀 Welcome to PractCode's coding competition! Get ready
                        to push your limits, solve challenges, and conquer the
                        leaderboard. Whether you're a seasoned coder or just
                        starting, join us for an electrifying journey of
                        learning and victory. Let's ignite our passion for
                        coding and make every line count! 💻🔥
                      </p>

                      <div className="banner-btn">
                       
                        <NavLink exact="true"  className="cv-btn" >Comming Soon</NavLink>
                      </div>
                      <div>



                      </div>

                    </div>
                  </div>

                  <div className="col-lg-5 offset-lg-1">
                    <div className="banner-img-two">
                      <img src={vector1} alt="iphone" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="banner-shape">
            <img src={shape5} alt="shape" />
            <img src={shape4} alt="shape" />
            <img src={shape3} alt="shape" />
          </div>
        </div>



        {/* <br />
        <h3 className="contest-title-heading">Contests</h3>
        <div className="contest-div">
          {contest_data.map(item => (
            <ContestCard key={item.title} className="contest-card-component" cardTitle={item.title} description={item.description}  link={authentication_data.isLoggedIn?`contest/${item.tag}`:`/user/login/contest-${item.tag}`}/>

          ))}

        </div>
        <br />
        <br /> */}

      </div>
    </>
  );
};

export default Home;
