import React from "react";
import { useParams } from 'react-router-dom';
import "../../Assets/style/pages-styles/login.css";
import { useState } from "react";
import {AuthenticatContext,UserAuthContext} from "../../context/AuthenticatContext";
import { useContext } from "react";
import { NavLink, Navigate } from "react-router-dom";

import Cookies from 'js-cookie';

export default function Login(props) {
  
  // AUTHENTICATION PARAMENTERS
  const { loginredirect } = useParams();
  const { authentication_data, update_authentication } = useContext(AuthenticatContext);
  const { UserAuthdata, update_UserAuthData } = useContext(UserAuthContext)

  const [username, setInputusername] = useState("");
  const [password, setInputpassword] = useState("");
  const [responseError, setError] = useState({
    msg: "",
    flag: { color: "green" },
  });
  const [userData, setUserData] = useState({});

  const [isloading, setloading] = useState(false);

  const setCookie = (name, value, hoursToExpire,userData) => {
    const expirationDate = new Date();
    // expirationDate.setDate(expirationDate.getDate() + daysToExpire);
    expirationDate.setTime(
      expirationDate.getTime() + hoursToExpire * 60 * 60 * 1000
    );
    const cookieValue = `${name}=${encodeURIComponent(
      value
    )};expires=${expirationDate.toUTCString()};path=/`;

     // Store userData in a cookie
    document.cookie = cookieValue;
    Cookies.set('userData', JSON.stringify(userData), { expires: hoursToExpire/24 });
    // update the Authcontext-states
    update_UserAuthData({"userId":userData['id'],"username":userData['user']})
    update_authentication({ authtoken: value, isLoggedIn: true });
  };

  const getlogin = async (e) => {
    e.preventDefault();

    try {
      setloading(true);
      const response = await fetch(
        "https://www.practcode-api.com/user/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
          body: JSON.stringify({ email: username, password: password }), // Replace with your data
        }
      );
      const data = await response.json();
      setUserData(data)
      setloading(false);
      if (response.ok) {
       
        // Set a token in a cookie with a 1 hours- expiration
        setCookie("authToken", data.authToken, 2,data);
        setError({ msg: "Logged in !", flag: { color: "green" } });

      } else {
        setError({ msg: `${data.errors[0]["msg"]}`, flag: { color: "red" } });
      }
    } catch (error) {
      setError("Error fetching data from the API");
      console.log(error.message);
    }
  };

  const getlogin_hackest = async (e) => {

    e.preventDefault();

    try {
      setloading(true);
      const response = await fetch(
        "https://practcode-server.vercel.app/hackfest-user/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
          body: JSON.stringify({ email: username, password: password }), // Replace with your data
        }
      );
      const data = await response.json();
      setloading(false);
      if (response.ok) {

        var Localdata = {
          userid: data['userId'],
          username: username,
          phone: password,
          Level: '2'
        }
        var hackest2024 = {
          1: {}
        }
        // Save the stringified object to local storage
        localStorage.setItem('user', JSON.stringify(Localdata));
        update_authentication({ authtoken: "hackfest2024", isLoggedIn: true });
        setError({ msg: "Logged in !", flag: { color: "green" } });
        console.log(data);
      } else {
        setError({ msg: `${data.errors}`, flag: { color: "red" } });
      }
    } catch (error) {
      setError("Error fetching data from the API");
      console.log(error.message);
    }
  };
  // ..............returning........................
  if (authentication_data.isLoggedIn) {
    // redirect the user to dashboard
    if (!loginredirect) {
      return <Navigate to={`/contest/preplacementtest2`} />
    } else {
      const redirect = (loginredirect.replace(/-/g, '/')).replace('/username',`/${[UserAuthdata['username']]}`);
    
     
      return <Navigate to={`/${redirect}`} />
    }
  } else {
    return (
      <>
        <div className="signupContainer">
          <div className="signuptopLogo">
            {/* <img src="{% static 'img/black&blue.png' %}" alt="" /> */}
          </div>
          <div id="loginmsg">error</div>
          <div className="signup_mainbox">
            <div className="signup_heading">
              <h2>{authentication_data.isLoggedIn ? "Continue" : "Log in"}</h2>
              <p>{authentication_data.isLoggedIn ? "" : "ACCESS YOUR ACCOUNT"}</p>
            </div>
            <p id="login-status-flag" style={responseError.flag}>
              {responseError.msg}
            </p>

            <form
              onSubmit={
                props.mode === "hackest2024-user" ? getlogin_hackest : getlogin
              }
            >
              <div style={authentication_data.isLoggedIn ? { display: "none" } : { display: "block" }}>
                <div>
                  <p id="usernameformerrorP"></p>
                  <div className="inputBoxandIcon">
                    <i className="fa-regular fa-user"></i>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      placeholder="Email"
                      required
                      onChange={(event) => {
                        setInputusername(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div>
                  <p id="passwordformerrorP"></p>
                  <div className="inputBoxandIcon">
                    <i className="fa-solid fa-lock"></i>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Password"
                      required
                      onChange={(event) => {
                        setInputpassword(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="formBtns">
                  <button className="signupBtn" type="submit">
                    {isloading ? (
                      <div className="loader">
                        <div className="inner_loader"></div>
                        <div className="inner_loader"></div>
                        <div className="inner_loader"></div>
                      </div>
                    ) : (
                      "Login"
                    )}
                    {/* loader */}

                    {/* -------- */}
                  </button>
                  <div className="frgtPswdDiv">
                    {props.mode === "hackest2024-user" ? (
                      "Powered By Practcode"
                    ) : (
                      ""
                      // <a href="/forgetpassword">Forgot Password ?</a>
                    )}
                  </div>
                </div>
              </div>
              <div className="LoginDirect">
                {props.mode === "hackest2024-user" ? (
                  <p>`{authentication_data.isLoggedIn ? (<NavLink to="/hackfest2024-coding-competition" state={userData}>Continue Coding Competition</NavLink>) : ("")}</p>

                ) : (
                  <p>
                    Not a User ?
                    <NavLink to="/user/signup"> Sign Up Now</NavLink>
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
