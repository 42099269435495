import React, { useEffect, useState } from 'react'
import { AgChartsReact } from 'ag-charts-react'

import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

// custom css
import '../../Assets/style/AG-charts/Scoreboard.css'



function Scoreboard(props) {
    //row data
    const [rowData, setRowData] = useState();
    const filterTableData = (Tabledata) => {
        // Sort the data by score in ascending order
        Tabledata.sort((a, b) => a.score - b.score);

        // Initialize an object to store the last assigned group for each score
        const lastGroupByScore = {};
        // Initialize a counter for serial numbers
        let serialNumber = 1;
        const groupedTableData = Tabledata.map(curr => {
            // Find the last assigned group for the current score
            const lastGroup = lastGroupByScore[curr.score] || String.fromCharCode(65 + Object.keys(lastGroupByScore).length);

            // Update the last assigned group for the current score
            lastGroupByScore[curr.score] = lastGroup;

            // Assign the serial number and increment it for the next user
            const userWithSerial = { user: curr.user, score: curr.score, group: lastGroup, serial: serialNumber };
            serialNumber++;

            return userWithSerial;
        });
        setRowData(groupedTableData)
    }
    //-------------------------------------------
    const [chartOptions, setChartOptions] = useState({
        height: 600,
        title: {
            text: "Practcode Contest",
        },
        series: [
            {
                type: "bar",
                direction: "horizontal",
                xKey: "group",
                yKey: "score",
                cornerRadius: 4,
            }
         
        ],
        axes: [
            {
                type: "category",
                position: "left",
                title: {
                    enabled: true,
                    text: "Groups",
                }
            },
            {
                type: "number",
                position: "bottom",
                title: {
                    enabled: true,
                    text: "Scores",
                },
            },
        ],
    });

    useEffect(() => {
        // Update chartOptions when props.data changes

        // Group the data by score
        const groupedData = props.data.reduce((acc, curr) => {
            const existingItem = acc.find(item => item.score === curr.score);
            if (existingItem) {
                existingItem.user += `,${curr.user}`;
            } else {
                // Find the group number based on the length of the accumulator array
                const groupNumber = String.fromCharCode(65 + acc.length); // Convert ASCII code to character (A, B, C, ...)
                acc.push({ user: curr.user, score: curr.score, group: groupNumber });
            }
            return acc;
        }, []);

        setChartOptions(prevOptions => ({
            ...prevOptions,
            data: groupedData
        }));
        // for the scoreboard table
        filterTableData(props.data);
        
    }, [props.data]);

    // table
    // Column Definitions: Defines & controls grid columns.
    const [colDefs, setColDefs] = useState([
        { field: "serial" },
        { field: "user" },
        { field: "group" },
        { field: "score" },
        

    ]);
    return (
        <> 
        {/* <div>
            <AgChartsReact options={chartOptions}  ></AgChartsReact>
        </div> */}
            <br /><br />
            <div
                className={
                    "ag-theme-quartz-dark"
                }
                style={{ width: "100%", height: "90vh" }}
            >
                <AgGridReact rowData={rowData} columnDefs={colDefs} />
            </div>
        </>
    )
}

export default Scoreboard