import React, { useEffect, useState } from "react";
import { AuthenticatContext, UserAuthContext } from "./AuthenticatContext";

import Cookies from "js-cookie";

const AuthenticatState = (props) => {
  // storing states
  const [authentication_data, update_authentication] = useState({
    authtoken: "",
    isLoggedIn: "",
  });
  const [UserAuthdata, update_UserAuthData] = useState({});
  useEffect(() => {
    // Define a function to fetch the authentication token
    const fetchAuthToken = () => {
      const authToken = Cookies.get("authToken");
      if (authToken) {
        console.log("AuthToken from cookies:", authToken);
        // Update the state with the fetched token
        update_authentication({ authtoken: authToken, isLoggedIn: true });
        // Retrieve userData from the cookie
        const cookieData = Cookies.get("userData");
        if (cookieData) {
          // Parse the JSON string back to an object
          const userDataObject = JSON.parse(cookieData);
          update_UserAuthData({"userId":userDataObject['id'],"username":userDataObject['user']});
        }
      } else {
        console.log("AuthToken not found in cookies");
        // Update the state with an empty token
        update_authentication({ authtoken: "", isLoggedIn: false });
      }
    };

    // Call the function to fetch the authentication token
    fetchAuthToken();
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  return (
    <>
      <AuthenticatContext.Provider
        value={{ authentication_data, update_authentication }}
      >
        <UserAuthContext.Provider value={{ UserAuthdata, update_UserAuthData }}>
          {props.children}
        </UserAuthContext.Provider>
      </AuthenticatContext.Provider>
    </>
  );
};
export default AuthenticatState;
