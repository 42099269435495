import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from "react";
import CodeEditor from "../../Basic-components/contest-components/CodeEditor";
import "../../../Assets/style/pages-styles/CodingcontestPage.css";
import { fetchOnline_Test } from "../../Api/online-test-fetch-data";
import { useLocation } from "react-router-dom";
import { AuthenticatContext, UserAuthContext } from "../../../context/AuthenticatContext";
import { NavLink, Navigate } from "react-router-dom";

function QuizContestPage() {
    useEffect(() => {

        const handleKeyDown = (event) => {
          if (event.ctrlKey) {
            event.preventDefault(); // Prevent default behavior
            // Optionally, you can notify the user that Ctrl key is disabled.
            // alert('Ctrl key is disabled.');
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        // Cleanup function to remove the event listener when component unmounts
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []); // Empty dependency array ensures that the effect runs only once
    
  // AUTHENTICATE PARAMETTERS
    const { authentication_data, update_authentication } = useContext(AuthenticatContext);
    const { UserAuthdata, update_UserAuthData } = useContext(UserAuthContext)
    const { contestName } = useParams();
    console.log(contestName)

    const [queLevel, setqueLevel] = useState(localStorage.getItem("currentLevel") ?? 2)
    const [test_data, setTest_data] = useState("");
    const [question_index, setquestion_index] = useState(0);
    const [levelsCleared] = useState(new Set());

    const [isloading_data, setloading_data] = useState(true);
    // editor
    const [editorValue, setEditorValue] = useState("");
    const l = useLocation()
    const handleEditorChange = (value) => {
        setEditorValue(value);
    };
    // code Output
    const [Codeoutput, setCodeoutput] = useState({ output: {} });
    //  user batch toggle
    const [userbatchVissible, setuserbatchVissible] = useState(false)

    // reload/refreshhandler
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Cancel the event
            event.preventDefault();
            // Chrome requires returnValue to be set
            event.returnValue = '';
            // Show a confirmation dialog
            const confirmationMessage = 'Are you sure you want to leave? All changes will be lost.';
            event.returnValue = confirmationMessage; // For Chrome
            return confirmationMessage; // For other browsers
        };

        // Add event listener for beforeunload event
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    //

    const fetchDataFromApi = async () => {
        try {

            const apiData = await fetchOnline_Test(contestName,"quiz");
            setTest_data(apiData);
            setTimeout(() => {
                setloading_data(false);
            }, 1);
        } catch (error) {
            // Handle error if needed
            console.log(error);
        }
    };
    useEffect(() => {
        // Function to call only once when component is loaded
        fetchDataFromApi(contestName);
    }, []); // E



    const next = async () => {
        if (question_index < Object.keys(test_data[0]["questions"]).length - 1) {
            setquestion_index(question_index + 1);
            setEditorValue("");
            setCodeoutput({ output: {} });
        } else {
            setquestion_index(question_index);
        }

        if(question_index===Object.keys(test_data[0]["questions"]).length){
            return true
        }


    };
    const prev = async () => {
        if (question_index <= Object.keys(test_data[0]["questions"]).length - 1 && question_index !== 0) {
            setquestion_index(question_index - 1);
            setEditorValue("");
            setCodeoutput({ output: {} });
        } else {
            setquestion_index(question_index);
        }

    };
    // user
    const userBatchToggle = (callby) => {
        console.log(callby)
        if (callby === "inside") {
            if (userbatchVissible) {
                setuserbatchVissible(false);
            } else {
                setuserbatchVissible(true)
            }
        }
        if (callby === "outside") {
            setuserbatchVissible(false);
        }
    }



    return (

        <>
            <div style={{ width: "90%", margin: "auto" }} >
                {/* editor area */}
                {isloading_data ? (
                    <div className="Preloader-hackthon-container">
                        <div className="centered-div">
                            <div className="test-generatloader"></div>
                            <h6>PractCode: Crafting Your Tests with Ease!</h6>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="header-div">
                            <h3>{test_data[0]["title"]}</h3>
                            <div>
                                <button className='btn-profile-contest' onClick={() => userBatchToggle("inside")} >Profile</button>
                            </div>
                        </div>
                        <div className='user-batch' style={userbatchVissible ? { display: "block", opacity: "1" } : { display: "none" }}>
                            <p className='username'>{UserAuthdata['username']}</p>
                            <p><NavLink><i className="fa-solid fa-arrow-up-right-from-square fa-2xs"></i> </NavLink>Leaderbord</p>
                            <p><i className="fa-solid fa-chart-simple fa-xs" style={{ color: "#B197FC" }}></i>Progress</p>
                        </div>
                        <br />
                        <br />

                        <CodeEditor
                            contestId = {test_data[0]['_id']}
                            EditorMode = "quizMode"
                            outside_clickHandler={() => userBatchToggle("outside")}
                            level={queLevel}
                            setqueLevel={setqueLevel}
                            editorValue={editorValue}

                            editorhandler={handleEditorChange}
                            Codeoutput={Codeoutput}
                            setCodeoutput={setCodeoutput}
                            queSet={[question_index, Object.keys(test_data[0]["questions"]).length]}
                            id={test_data[0]["_id"]}

                            next_que={next}
                            prev_que={prev}
                            levelsCleared={levelsCleared}
                            // currentQuestion={}
                            question={Object.keys(test_data[0]["questions"])[question_index]}
                            explaination={""}
                            example={""}
                            // options for quiz
                            options = {test_data[0]["questions"][Object.keys(test_data[0]["questions"])[question_index]]['options']}

                        />
                    </div>
                )}
            </div>
        </>
    )

}

export default QuizContestPage