import React, { useState, useRef, useEffect, useContext } from "react";
import AceEditor from "react-ace";
import "../../../Assets/style/Basic-component-styles/CodeEditor.css";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/theme-dracula";
import { NavLink, json, useLocation, useNavigate } from "react-router-dom";
import { UserAuthContext } from "../../../context/AuthenticatContext";
// API call
import { postQuiz } from '../../Api/online-test-fetch-data'
import { all } from "axios";

export default function CodeEditor(props) {
  useEffect(() => {

    const handleKeyDown = (event) => {
      if (event.ctrlKey) {
        event.preventDefault(); // Prevent default behavior
        // Optionally, you can notify the user that Ctrl key is disabled.
        // alert('Ctrl key is disabled.');
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array ensures that the effect runs only once

  const [showResult, setShowresult] = useState(false)
  // quiz question done

  const [submited_question, setSubmited_question] = useState([])

  const divRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [submite_state, setsubmite_state] = useState(false)
  const [output_Style, setOutput_style] = useState({ display: "none" });
  const [isloading_data, setloading_data] = useState(false);
  const isCurrentLevelCleared = useRef(false);
  // State to manage the selected language
  const [selectedLanguage, setSelectedLanguage] = useState("python");
  // AUTHENTICATE PARAMETTERS
  const { UserAuthdata, update_UserAuthData } = useContext(UserAuthContext)


  // const location = useLocation()
  const navigate = useNavigate()
  const currentQuestion = props.queSet[0]

  // Function to handle language selection change
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    console.log(event.target.value)
  };
  // question-remaining progress bar
  const [questionRemain, setquestionRemain] = useState(0)
  // ------------------------------

  // submit code
  const submit_code = async () => {

    try {
      setloading_data(true);
      setOutput_style({ display: "none" }); // Assuming you want to hide output until new data is fetched

      //-------------
      const response = await fetch(
        "https://www.practcode-api.com/coderunner/submit-code",
        // "http://127.0.0.1:5000/coderunner/submit-code",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
          body: JSON.stringify({
            code: props.editorValue,
            language: selectedLanguage,
            id: props.id,
            username: UserAuthdata.userId,
            time: currentTime.toLocaleTimeString(),
            public: props.Codeoutput,
            question: props.question

          }),

        }
      );

      const json = await response.json();
      props.setCodeoutput(json);

      setOutput_style({ display: "block" });
      props.onSubmit(json.ress ? json.ress : "Message not found !!");
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setloading_data(false);
      setsubmite_state(false);



    }
  }
  // code runner
  const coderunner = async () => {
    let response;
    try {
      setloading_data(true);
      setOutput_style({ display: "none" }); // Assuming you want to hide output until new data is fetched

      response = await fetch(
        "https://www.practcode-api.com/coderunner",
        // "http://127.0.0.1:5000/coderunner",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
          body: JSON.stringify({
            code: props.editorValue,
            language: selectedLanguage,
            id: props.id,
            username: UserAuthdata['username'],
            question: props.question
          }),
        }
      );

      const json = await response.json();
      response = json;
      props.setCodeoutput(json);
      setOutput_style({ display: "block" });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setloading_data(false);
      setsubmite_state(true);
    }
  };

  // Scroll to bottom of div when data updates
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTo({
        top: divRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [props.Codeoutput]);
  // Watch for changes in the data state

  //for quiz options
  const [selectedOption, setSelectedOption] = useState(null);
  const submitQuiz = async () => {
    console.log(selectedOption)
    if (selectedOption) {
      const quiz_response = await postQuiz(UserAuthdata['userId'], props.contestId, props.question, selectedOption)
      setSubmited_question([...submited_question, quiz_response])
      setSelectedOption(null)
      var res = await props.next_que()
      if (props.queSet[0] + 1 === props.queSet[1]) {
        setShowresult(true)
      }

    } else {
      alert('Select the option')
    }

  }



  const handleOptionClick = (option) => {
    setSelectedOption(option);
    // Optionally, you can pass the selected option to a parent component using props.handleOptionClick(option)
  };

  const formattedExample = props.example.replace(/<new>/g, '<br />');

  return (
    <div onClick={props.outside_clickHandler} >
      {/* progress */}
      <div className="question-remainning-bar" style={{ width: `${[(((props.queSet[0] + 1) * 100) / props.queSet[1])]}%` }}></div>


      <div className="pacrtice-code-question-div">
        <div className={`code-question ${props.EditorMode === "quizMode" ? "quizMode" : "codeMode"}`}>
          <div className={`question-area ${props.EditorMode === "quizMode" ? "quizMode" : "codeMode"}`} >{/* Render the content with line breaks */}
            {props.EditorMode === "quizMode" ? <pre>
              {props.question.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />

                </React.Fragment>
              ))}
            </pre> : <>

              {props.question.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />

                </React.Fragment>
              ))}

            </>}
            {/* quiz options */}
            {
              <div className="options-container">
                {props.options ? (
                  props.options.map((option, index) => (
                    <div
                      className={`option-item ${option === selectedOption ? 'selected-quiz-option' : ''}`}
                      key={index}
                      onClick={() => handleOptionClick(option)}
                    >
                      {option}
                    </div>
                  ))

                ) : (
                  <></>
                )}

              </div>


            }
            {submited_question.includes(props.question) ? <button className="quiz-submit-button question-submited">Question submitted and Locked </button> : ""}
          </div>

          <p className="question-explaination" style={props.example ? {} : { display: "none" }}>{props.explaination}</p>
          <b >{props.example ? "Example" : ""}</b>
          <pre style={props.example ? { whiteSpace: "pre-wrap" } : { display: "none" }} dangerouslySetInnerHTML={{ __html: formattedExample }}>
            {/* Render the content with line breaks
            {props.example ? props.example.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            )) : " "} */}

          </pre>
          <br />
          {props.EditorMode === "codeEditor" ? <div> <b>language preference</b>
            <br />
            <span
              style={{
                background: "#7d69ff",
                color: "white",
                padding: "1px 7px",
                borderRadius: "5px",
                margin: "5px",
              }}
            >
              Python
            </span>
            <span
              style={{
                background: "#7d69ff",
                color: "white",
                padding: "1px 7px",
                borderRadius: "5px",
                margin: "5px",
              }}
            >
              Java
            </span>
            <span
              style={{
                background: "#7d69ff",
                color: "white",
                padding: "1px 7px",
                borderRadius: "5px",
                margin: "5px",
              }}
            >
              C
            </span>
            <span
              style={{
                background: "#7d69ff",
                color: "white",
                padding: "1px 7px",
                borderRadius: "5px",
                margin: "5px",
              }}
            >
              C++
            </span></div> : ""}
          {/* next prev btns */}
          <div className="nextprev-question-btn-div">
            {showResult ? <NavLink to={`/dashboard/${UserAuthdata['userId']}`}><button>Check Result</button> </NavLink> : <button style={{ background: "transparent" }} ></button>}
            <button onClick={props.prev_que}>prev</button>
            <p>{props.queSet[0] + 1} of {props.queSet[1]}</p>
            <button onClick={props.next_que}>Next</button>
          </div>
        </div>



        {props.EditorMode === "codeEditor" ? <div className="editor-area" ref={divRef}>
          <div className="user-code">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>Editor  <b>{selectedLanguage === "java" ? "- Public class name should be Main" : ''}</b></h5>{" "}
              <div>
                <b>Language </b>
                <select
                  className="language-select"
                  style={{ marginRight: "10px" }}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                >
                  {/* Default option */}
                  <option value="python">python</option>
                  {/* Option for each language */}
                  <option value="java">java</option>
                  <option value="c">C</option>
                  <option value="cpp">C++</option>
                  {/* Add more options as needed */}
                </select>{" "}
              </div>
            </div>
            <div style={{ padding: "10px" }}>
              <AceEditor
                id="editor"
                style={{
                  width: "100%",
                  position: "relative",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
                mode={selectedLanguage === "c" || selectedLanguage === "cpp" ? "c_cpp" : selectedLanguage}
                theme="dracula"
                name="my-editor"
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={props.editorValue}
                onChange={props.editorhandler}
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  // tabSize: 1,
                }}
              ></AceEditor>
            </div>
          </div>
          <div className="user-code-btnsection">
            <button
              className="coderunner-btn"
              id="coderunner-btnID"
              onClick={coderunner}
              disabled={props.editorValue === "" ? true : false}
              style={
                props.editorValue === ""
                  ? { background: "gray" }
                  : { background: "#5340ff" }
              }
            >
              Test code
            </button>
            <i
              className="fa-solid fa-spinner fa-spin fa-xl"
              style={isloading_data ? { display: "block" } : {}}
            ></i>
            <button className="submit-btn" onClick={submit_code} style={submite_state ? { visibility: "visible" } : { visibility: "hidden" }}>
              {" "}
              Submit
            </button>

          </div>
          <div id="editor-box-id" style={output_Style}>
            <b>{typeof props?.Codeoutput?.output == "object" && Object.keys(props.Codeoutput["output"]).length !== 0 ? (
              <h3
                style={
                  "error" in props.Codeoutput["output"]
                    ? { color: "red" }
                    : { color: "#1ba94c" }
                }
              >
                {"error" in props.Codeoutput["output"] ? "Error" : "Executed"}
              </h3>
            ) : ("")}
            </b>

            <div className="code_output">
              <div className="Testcase_messages">
                <h6>Code Runner Message</h6>
                <p className="coderunnermessage">
                  {typeof props?.Codeoutput?.output != "object" ? JSON.stringify(props?.Codeoutput["ress"]) : "error" in props.Codeoutput["output"]
                    ? props.Codeoutput["output"]["error"]
                    : "succes"}
                </p>
              </div>
              <div className="Testcase_span">
                {!Object.keys(props.Codeoutput).includes("Submited") ? Object.entries(props.Codeoutput["output"]).map(([key, value]) => (
                  <div className="Internal-usercode-outerbox" key={key !== "error" ? key : ""}>
                    <i
                      className={
                        key !== "error"
                          ? `fa-solid  fa-lg fa-square-${value ? "check" : "xmark"
                          }`
                          : ""
                      }
                      style={value ? { color: "#1ba94c" } : { color: "red" }}
                    ></i>{" "}
                    {key !== "error" ? ` Test case ${parseInt(key) + 1}` : ""} <br />
                    {key !== "error" ? <div className="Internal-usercode-output Testcase-Inputs" >Testcase Inputs   :  {
                      Object.keys(props.Codeoutput['usercode'])[key]
                        .replace(/[\[\]"]/g, '') // Remove square brackets and double quotes
                        .split('\\n')             // Split the string on each "\n" to create new lines
                        .map((line, index) => (
                          <div key={index}>{line}</div> // Render each line inside a <div>
                        ))
                    }</div> : ""}
                    <div>Output : </div>
                    {key !== "error" ? <pre style={{ whiteSpace: "pre-wrap" }} className="Internal-usercode-output output"> {props.Codeoutput['usercode'][Object.keys(props.Codeoutput['usercode'])[key]]['actual'].split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))} </pre> : ""}
                    <div>Expected Output : </div>
                    {key !== "error" ? <pre style={{ whiteSpace: "pre-wrap" }} className="Internal-usercode-output Expected-Output">{props.Codeoutput['usercode'][Object.keys(props.Codeoutput['usercode'])[key]]['expected'].split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))} </pre> : ""}

                  </div>
                )) : <div></div>}
              </div>

            </div>
          </div>
        </div> : ""}
      </div>
    </div>
  );
}
