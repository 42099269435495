// load server url
import endpoints from './endpoints.json';


// get contest data 
export const getContest = async () => {
    
    try {
        const response = await fetch(`${endpoints.server1}/contests/all-details`, {
         
          method: 'GET'
      
      });
      const data = await response.json();
   
      return data
} catch (error) {
  console.error('Error fetching data:', error);
  throw error;
}
};