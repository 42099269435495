import React from 'react'
import AceEditor from "react-ace";
import "../../Assets/style/pages-styles/compiler.css";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/theme-dracula"
// import roboGif from '../../Assets/image/gifs/giphy.gif'
import { useState } from 'react';
function Compiler() {
    // change height
    const [isClicked, setIsClicked] = useState(false);
    const [height, setHeight] = useState('90vh');
    // Responses
    const [output, setoutput] = useState('')
    const [codeError, setcodeError] = useState('')

    // State to manage the selected language
    const [selectedLanguage, setSelectedLanguage] = useState("python");
    const [editorValue, setEditorValue] = useState("");
    const handleEditorChange = (value) => {
        setEditorValue(value);
        setHeight('90vh')
        setIsClicked(false)
    };
    // Function to handle language selection change
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
        console.log(event.target.value)
    };
    // run
    const run = async () => {
        var response;
        
            setIsClicked(true);
            setHeight('50vh');
     
        // api
        try {
            response = await fetch(
                "https://practcode-api.com/coderunner/run",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        // Add any other headers as needed
                    },
                    body: JSON.stringify({
                        code: editorValue,
                        language: selectedLanguage,
                        mode: "compiler"
                    }),
                }
            );

            var ResData = await response.json()
            console.log(ResData)
            if (ResData[0]['error']) {
                setcodeError(ResData[0]['error'])
            }else{
                setcodeError('')
            }

            setoutput(ResData[1]['output'])
        } catch (error) {
            console.log(ResData)
        }
    };
    return (
        <div>
            <div className='MainEditor'>
                <div className='MainEditor-tabs'>
                    <div>
                        <button style={{ marginRight: "14px", padding: "5px" }}>Practcode</button>
                        <button> Main.py </button>

                    </div>
                    <div>
                        <button className='codeRun-btn' onClick={run}> <b>Run </b> <i className="fa-regular fa-circle-play"></i> </button>

                        <select
                            className="language-select"
                            style={{ marginRight: "10px" }}
                            value={selectedLanguage}
                            onChange={handleLanguageChange}
                        >
                            {/* Default option */}
                            <option value="python">python</option>
                            {/* Option for each language */}
                            <option value="java">java</option>
                            <option value="c">C</option>
                            <option value="cpp">C++</option>
                            {/* Add more options as needed */}
                        </select>{" "}
                    </div>

                </div>
                <div className='Editor-container'>
                    <AceEditor
                        id="editor"
                        style={{
                            width: "90%",
                            position: "relative",
                            height,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            transition: 'height 0.5s ease' // Smooth transition for height change
                        }}
                        mode={selectedLanguage === "c" || selectedLanguage === "cpp" ? "c_cpp" : selectedLanguage}
                        theme="dracula"
                        name="my-editor"
                        fontSize={16}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={editorValue}
                        onChange={handleEditorChange}
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                    ></AceEditor>
                    <AceEditor
                        id="editor"
                        style={{
                            width: "10%",
                            height,
                            wordWrap: "",
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                            transition: 'height 0.5s ease' // Smooth transition for height change
                        }}
                        wrapEnabled={true}
                        showGutter={false} // Hide the line number gutter
                        mode={selectedLanguage === "c" || selectedLanguage === "cpp" ? "c_cpp" : selectedLanguage}
                        theme="dracula"
                        name="my-editor"
                        fontSize={5}
                        showPrintMargin={true}

                        highlightActiveLine={true}
                        value={editorValue}
                        onChange={handleEditorChange}
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                    ></AceEditor>
                </div>
            </div>
            <div className='Output-mainScreen' style={{ height: "fit-content" }}>
                <div className='output-tab'>
                    <div className='output-tab-btns'>
                        <button >Terminal</button>
                        <button style={codeError === "" ? {} : { background: "red" }}>Error</button>
                    </div>

                </div>
                <div className='output-div' style={output === "" || codeError === "" ? {  } : {}} >
                    <pre className='pl' style={{ whiteSpace: "pre-wrap" }}>
                        {output?output.split("\n").map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        )):""}
                    
                    </pre>

                    
                    





                    <p>{codeError}</p>
                </div>
            </div>

        </div>
    )
}

export default Compiler