// load server url
import endpoints from './endpoints.json';




// get course data java only
export const fetchData = async () => {
    try {
        const response = await fetch(`${endpoints.server1}/getcourses/learn-java`, {
            method: 'GET'
        
          });
          const data = await response.json();
       
          return data
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
};
// get course data java only - topics
export const Java_topics = async (lession,topic) => {
  try {
      const response = await fetch(`${endpoints.server1}/getcourses/learn-java/lessons-topics`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },
          body: JSON.stringify({ lession: lession, topic: topic }),

        });
        const data = await response.json();
     
        return data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};