import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from "react";

import "../../../Assets/style/pages-styles/contestPage.css";
import { fetchOnline_Test } from "../../Api/online-test-fetch-data";
import { useLocation } from "react-router-dom";
import { AuthenticatContext, UserAuthContext } from "../../../context/AuthenticatContext";

import { NavLink, Navigate } from "react-router-dom";
import favlogo from "../../../Assets/image/fav1.png"
import Navbar from '../../Navbar'
import Sucess from '../../Alerts/Sucess';

function ContestPage() {
  
  useEffect(() => {

    const handleKeyDown = (event) => {
      if (event.ctrlKey) {
        event.preventDefault(); // Prevent default behavior
        // Optionally, you can notify the user that Ctrl key is disabled.
        // alert('Ctrl key is disabled.');
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array ensures that the effect runs only once

  // AUTHENTICATE PARAMETTERS
  const { authentication_data, update_authentication } = useContext(AuthenticatContext);
  const { UserAuthdata, update_UserAuthData } = useContext(UserAuthContext)
  const { contestName } = useParams();
  const [isloading_data, setloading_data] = useState(true);

  const [test_data, setTest_data] = useState("");
  const [hideStartButton,setHideStartButton] = useState({"coding":true,"quiz":true });



  const fetchDataFromApi = async () => {
    try {
      if(localStorage.getItem(`codingSubmitted_${contestName}`)){
        var f = hideStartButton.quiz;
        setHideStartButton({"coding":false,"quiz":f})
      }
      console.log("Submited : ",localStorage.getItem(`codingSubmitted_${contestName}`));
      const apiData = await fetchOnline_Test(contestName,"all");
      setTest_data(apiData);
      setTimeout(() => {
        setloading_data(false);
      }, 1);
    } catch (error) {
      // Handle error if needed
      console.log(error);
    }
  };
  useEffect(() => {
    // Function to call only once when component is loaded
    fetchDataFromApi(contestName);
  }, []); // E

  // user

  if (authentication_data.isLoggedIn) {
    return (

      <>
        {isloading_data ? (
          <div className="Preloader-hackthon-container">
            <div className="centered-div">
              <div className="test-generatloader"></div>
              <h6>PractCode: Crafting Your Tests with Ease!</h6>
            </div>
          </div>
        ) : (

          <div className='contest-main-div'>

            <Navbar sidebar={{}} />

           
            <div className='contest-top-heading' >
              <p>{'Practcode contests > ' + test_data[0]["title"]}</p>

            </div>
            <div className='contest-title'>
              <h5>{test_data[0]["title"]}</h5>
              
            </div>

            {/* <div className="user-batch">
              <p className='username'>{UserAuthdata.username}</p>
            </div> */}

            <br />
            <br />
            <div className="card-area">
              
              <div className="quiz contest-card">
                <div className="card-heading-container">
                  <div className="design-container"></div>
                  <p>Quiz Questions <br />Schedule: {test_data[0]['quiz_starting_time']}</p>
                  
                  <NavLink to={`https://contest.practcode.com/quiz/${contestName}/${UserAuthdata.userId}`}><button className='solve-card-btn'>Start Now </button></NavLink> 
                </div>
              </div>
              <div className="coding contest-card">
                <div className="card-heading-container">
                  <div className="design-container"></div>
                  <p>Coding Questions <br />Schedule: {test_data[0]['coding_starting_time']}</p>
                  {/* <NavLink ><button className='solve-card-btn'>Start Now </button></NavLink>  */}
                  <NavLink to={`/contest/coding/${contestName}`}><button className='solve-card-btn'>Start Now </button></NavLink> 
                </div>

              </div>

            </div>
          </div>
        )}
       
      </>
    )
  } else {
    // login required
    return <Navigate to={`/user/login/contest-${contestName}`} />
  }
}

export default ContestPage