import React, { useState } from 'react';
import '../../Assets/style/Basic-component-styles/Internal_quiz.css'


export default function Internal_quiz(props){
    const [loadquiz, setloadquiz] = useState(false)
    return(
    
    <div className='Internal-quiz'>

    <div>
        <p className='quiz-question-tag'>What is java ?</p>
        <div className='quiz-Options'>
            <button className='Option-box'> Option 1 </button>
            <button className='Option-box'> Option 2 </button>
            <button className='Option-box'> Option 3 </button>
            <button className='Option-box'> Option 4 </button>
        </div>
    </div>

    </div>
    
    
    
    
    
    )
}