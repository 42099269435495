import React, { useContext, useEffect, useState } from "react";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import CodeEditor from "../../Basic-components/contest-components/CodeEditor";
import "../../../Assets/style/pages-styles/CodingcontestPage.css";
import { fetchOnline_Test } from "../../Api/online-test-fetch-data";
import { AuthenticatContext, UserAuthContext } from "../../../context/AuthenticatContext";
import Sucess from "../../Alerts/Sucess";

function CodingContestPage() {
    const { authentication_data, update_authentication } = useContext(AuthenticatContext);
    const { UserAuthdata, update_UserAuthData } = useContext(UserAuthContext);
    const { contestName } = useParams();
    const [queLevel, setqueLevel] = useState(localStorage.getItem("currentLevel") ?? 2);
    const [test_data, setTest_data] = useState("");
    const [question_index, setquestion_index] = useState(0);
    const [levelsCleared] = useState(new Set());
    const [isloading_data, setloading_data] = useState(true);
    const [editorValue, setEditorValue] = useState("");
    const [Codeoutput, setCodeoutput] = useState({ output: {} });
    const [userbatchVissible, setuserbatchVissible] = useState(false);

    const [timer, setTimer] = useState("");
    const [startTime, setStartTime] = useState(""); // Start time as state
    const [duration, setDuration] = useState(60); // Duration in minutes as state
    const [contestCompleted, setContestCompleted] = useState(false); // State to manage contest completion
    const [contestStarted, setContestStarted] = useState(false); // State to manage contest start status

    // success alert
    const [showAlert, setShowAlert] = useState(false);
    const [alertMSG ,setalertMSG] = useState("success !");
    const showAlertHandler = () => {
        setShowAlert(true);
        // Automatically hide the alert after 2 seconds by the Sucess component itself
      };
    // =============
    const navigate = useNavigate()

    const handleEditorChange = (value) => {
        setEditorValue(value);
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
            const confirmationMessage = 'Are you sure you want to leave? All changes will be lost.';
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const apiData = await fetchOnline_Test(contestName, "coding");
                setTest_data(apiData);
                setStartTime(apiData[0]["coding_starting_time"]);
                setTimeout(() => {
                    setloading_data(false);
                }, 1);
            } catch (error) {
                console.log(error);
            }
        };
        fetchDataFromApi();
    }, [contestName]); // Include contestName as a dependency

    useEffect(() => {
        if (!startTime) {
            // If startTime is not set, return early to avoid errors
            return;
        }

        const [startHour, startMinute, period] = startTime.match(/(\d+):(\d+)\s*(AM|PM)/i)?.slice(1) || [];
        if (!startHour || !startMinute || !period) {
            console.error('Invalid startTime format:', startTime);
            return;
        }

        const startHour24 = period.toUpperCase() === "PM" && startHour !== "12" ? +startHour + 12 : +startHour;
        const startDateTime = new Date();
        startDateTime.setHours(startHour24, startMinute, 0, 0);
        const durationInMilliseconds = duration * 60 * 1000;
        const endTime = new Date(startDateTime.getTime() + durationInMilliseconds);

        const updateTimer = () => {
            const now = new Date();
            if (now < startDateTime) {
                setTimer("00:00");
                setContestStarted(false);
            } else {
                setContestStarted(true);
                const timeDiff = endTime - now;
                if (timeDiff <= 0) {
                    setTimer("00:00");
                    setContestCompleted(true);
                } else {
                    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
                    setTimer(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
                }
            }
        };

        updateTimer();
        const timerInterval = setInterval(updateTimer, 1000);

        return () => {
            clearInterval(timerInterval);
        };
    }, [startTime, duration]);

    const next = () => {
        if (question_index < Object.keys(test_data[0]["questions"]).length - 1) {
            setquestion_index(question_index + 1);
            setEditorValue("");
            setCodeoutput({ output: {} });
        }
    };

    const prev = () => {
        if (question_index > 0) {
            setquestion_index(question_index - 1);
            setEditorValue("");
            setCodeoutput({ output: {} });
        }
    };

    const onSubmit = (alert) => {
        // console.log("before==", "test_data[0]['questions']", Object.keys(test_data[0]["questions"]));
        // const a = test_data[1]["questions"].filter( (e, ind) => ind != question_index )
        const a = Object.keys(test_data[0]["questions"])[question_index]
        // console.log("aa==", a);
        delete test_data[0]["questions"][a];
        // console.log("after===", Object.keys(test_data[0]["questions"]));
        // alert
        setalertMSG(alert)
        showAlertHandler();
        if(Object.keys(test_data[0]["questions"]).length == 0) {
            localStorage.setItem(`codingSubmitted_${contestName}`, 'true');
           
            window.location.href = `https://contest.practcode.com/result/${contestName}/${UserAuthdata['userId']}`;
        }
        setTest_data(test_data)
    }

    const userBatchToggle = (callby) => {
        if (callby === "inside") {
            setuserbatchVissible(!userbatchVissible);
        } else if (callby === "outside") {
            setuserbatchVissible(false);
        }
    };

    return (
        <>  
            {showAlert && <Sucess msg = {alertMSG} />}
            <div style={{ width: "90%", margin: "auto" }}>
                {isloading_data ? (
                    <div className="Preloader-hackthon-container">
                        <div className="centered-div">
                            <div className="test-generatloader"></div>
                            <h6>PractCode: Crafting Your Tests with Ease!</h6>
                        </div>
                    </div>
                ) : contestCompleted ? (
                    <div className="contest-completed-container">
                        <div className="contest-completed-message">
                            <h2>Contest Completed</h2>
                            <p>Thank you for participating!</p>
                        </div>
                    </div>
                ) : !contestStarted ? (
                    <div className="contest-completed-container">
                        <div className="contest-completed-message">
                            <h2>Contest Will Start At Its Scheduled Time</h2>
                            <p>The contest will start at {startTime}.</p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="header-div">
                            <h3>{test_data[0]["title"]}</h3>
                            <div className='coding-contest-Timer'>{timer}</div>
                        </div>
                        
                        <div className='user-batch' style={userbatchVissible ? { display: "block", opacity: "1" } : { display: "none" }}>
                            <p className='username'>{UserAuthdata['username']}</p>
                            <p><NavLink to={""}><i className="fa-solid fa-arrow-up-right-from-square fa-2xs"></i> </NavLink>Leaderboard</p>
                            <p><i className="fa-solid fa-chart-simple fa-xs" style={{ color: "#B197FC" }}></i>Progress</p>
                        </div>
                        <br />
                        <br />

                        <CodeEditor
                            EditorMode="codeEditor"
                            outside_clickHandler={() => userBatchToggle("outside")}
                            level={queLevel}
                            setqueLevel={setqueLevel}
                            editorValue={editorValue}
                            editorhandler={handleEditorChange}
                            Codeoutput={Codeoutput}
                            setCodeoutput={setCodeoutput}
                            queSet={[question_index, Object.keys(test_data[0]["questions"]).length]}
                            id={test_data[0]["_id"]}
                            next_que={next}
                            prev_que={prev}
                            levelsCleared={levelsCleared}
                            question={Object.keys(test_data[0]["questions"])[question_index]}
                            explaination={""}
                            example={test_data[0]["questions"][Object.keys(test_data[0]["questions"])[question_index]]['example']}
                            onSubmit={onSubmit}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default CodingContestPage;
