import React, { useEffect, useRef, useState } from "react";
import "../../Assets/style/pages-styles/mycourses.css";
import { fetchData,Java_topics } from "../Api/mycourse-fetch-data";
import Internal_quiz from "../Basic-components/Internal_quiz";
export default function MyCourses() {
  
  
  const isManualScrolling = useRef(false);
  const typedTextRef = useRef(null); // Reference to your text container
  const stopFlag = useRef(false);
  const insideSingleQuotes = useRef(false);
  const [Internal_quiz_visibility,setvisibility] = useState(false);
  const [typeText_currentIndex,setCurrent_typeIndex]=useState(0);
  const [isloading_data,setloading_data] = useState(true); 
  const [isMycourseSidebarVisible, setMycourseSidebarVisible] = useState(true);
  const [displayedText, setDisplayedText] = useState('');
  const [textToType, setTextToType] = useState("");
  const [currentText,setCurrentText] = useState(""); 
  const [isTyping, setIsTyping] = useState(false);
  const [islessionVisible, setlessionVisible] = useState({
    first: true,
    second: false,
    third: false,
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const apiData = await fetchData();
        setData(apiData);
        setloading_data(false)
        console.log(apiData)
      } catch (error) {
        // Handle error if needed
        console.log(error)
      }
    };

    fetchDataFromApi();
  }, []);
 

  const mycourses_sidebartoggle = () => {
    setMycourseSidebarVisible(!isMycourseSidebarVisible);
  };
  const lession_section_visibility = (key) => {
    setlessionVisible((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const fetchDataFromAPI = async (l,t) => {
    try {
      setCurrentText(t)
      const apiText = await Java_topics(l,t);
      setTextToType(apiText);
      
      return apiText;
    } catch (error) {
      // Handle error
    }
  };



  
  const typeTheContent = async (event) => {
    if (event === "resume") {
      await typeText(textToType);
    } else {
      if (!isTyping) {
        setDisplayedText(''); // Clear displayed text before typing new text
        stopFlag.current = false;
        setIsTyping(true);
  
        const fetchedText = await fetchDataFromAPI(event.target.className, event.target.textContent);
        await typeText(fetchedText);
  
        setIsTyping(false);
      }
    }
  };
  
  const typeText = async (text) => {
    let tag = false;         // Flag to check if we're inside an HTML tag
    let currentTag = '';      // Stores characters within an HTML tag
    let displayContent = '';  // Text to be progressively shown on the screen
  
    for (let i = 0; i < text.length && !stopFlag.current; i++) {
      const char = text[i];
  
      if (char === '<') {
        tag = true;          // Start of a new HTML tag
        currentTag += char;   // Begin storing the tag
      } else if (char === '>') {
        tag = false;          // End of the HTML tag
        currentTag += char;   // Complete the tag
        displayContent += currentTag; // Append completed tag to the display content
        setDisplayedText(displayContent); // Update displayed content
        currentTag = ''; // Reset tag for the next potential tag
      } else if (tag) {
        currentTag += char;   // Accumulate tag characters until it closes
      } else {
        // Handle special character sequences for newlines and tabs
        let textContent = char;
  
        if (text.substr(i, 2) === '\\n') {
          // textContent = '<br>';
          // i++;                          //just skip this part 
        } else if (text.substr(i, 2) === '\\t') {
          textContent = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
          i++;
        } else if (char === '.') {
          await new Promise(resolve => setTimeout(resolve, 1000)); // Pause after a period
        }
  
        displayContent += textContent; // Add regular characters to display content
        setDisplayedText(displayContent); // Update displayed content
  
        // Adjust typing delay
        await new Promise(resolve => setTimeout(resolve, 30));
      }
    }
  };
  // Render the displayed text with HTML parsing enabled
  
  


  useEffect(() => {
    if (typedTextRef.current && !isManualScrolling.current) {
      typedTextRef.current.scrollTop = typedTextRef.current.scrollHeight;
    }
  }, [displayedText, isTyping]);

  const handleScroll = () => {
    // Update the manual scrolling state
    isManualScrolling.current = typedTextRef.current.scrollTop + typedTextRef.current.clientHeight < typedTextRef.current.scrollHeight - 10;
  };

  const stopTyping = () => {
    if(insideSingleQuotes.current===false){
      stopFlag.current = true;
      insideSingleQuotes.current=false;
      setIsTyping(false);
    }
  };

  

  return (
    <div className="Mycourse-main-div">
      <div className={`Mycourses-sidebar ${isMycourseSidebarVisible ? "active-for-responsive" : ""}`}>
        <div
          className={`mycourse-sidebar-content  ${
            isMycourseSidebarVisible ? "active" : ""
          }`}
        >
          <h6>Learn - Java</h6>
          <i className="fa-solid fa-spinner fa-spin fa-xl" style={isloading_data?{display:"block"}:{display:"none"}}></i>
          {Object.entries(data).map(([key, items], index) => (
            <ul
              key={index}
              className={` ${islessionVisible[key] ? "show" : ""}`}
            >
              <h6
                onClick={() => lession_section_visibility(key)}
                className="lession-heading"
              >
                {key}
                <i
                  className={`fa-solid  ${
                    islessionVisible[key] ? "fa-angle-up" : "fa-angle-down"
                  }`}
                ></i>
              </h6>
              {items.map((item, itemIndex) => (
                <li key={itemIndex} className={key}  onClick={typeTheContent}>
                  {item}
                </li>
              ))}
            </ul>
          ))}
          
        </div>
        <div className="mycourse-sidebar-toggle-btn">
          <button onClick={mycourses_sidebartoggle}>
            <i
              className={`fa-solid   ${
                isMycourseSidebarVisible ? "fa-angle-left" : "fa-angle-right"
              }`}
            ></i>
          </button>
        </div>
      </div>
      {/* mycourse body */}
      <div className="mycourse-body-main-div" >
        <h4 style={{borderBottom:"2px solid #7d69ff",width:"max-content",paddingBottom:"6px"}}>{currentText} </h4>
        <div ref={typedTextRef} style={{ height: '580px', overflowY: 'auto' }} onScroll={handleScroll}>
          <div className="typedtext" style={Internal_quiz_visibility?{filter:'blur(3px)'}:{filter:'none' }} dangerouslySetInnerHTML={{ __html: displayedText }} />
          
          <i  className="fa-solid fa-ellipsis fa-fade" style={isTyping?{color:"#7d69ff",fontSize:"25px"}:{display:"none"}}></i>
          <div className="mycourse-internal-quize" style={isTyping || textToType===""?{ background: ''}:{ background: '#f0f2f8'}}>{isTyping || textToType===""?"":Internal_quiz_visibility?<Internal_quiz/>:<button onClick={()=>{setvisibility(true)}} className="Solve-Internalquiz-btn">Solve Quiz</button>}</div>
        </div>   
        
        {/* <button onClick={isTyping?stopTyping:resumeTyping} disabled={typeText_currentIndex===0?true:false} className="Stoptypingbtn"><i className={`fa-regular ${isTyping?'fa-circle-stop':"fa-circle-play"}`}></i></button> */}
      
      </div>

    </div>
  );
}
