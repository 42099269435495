
import Home from './components/Home'
import './Assets/style/Basic-component-styles/cards.css'

import Courses from './components/Pages/Courses';
import MyCourses from './components/Pages/MyCourses';
import Login from './components/Pages/Login'
import Signup from './components/Pages/Signup'
import Hackthon from './components/Pages/Hackthon'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthenticatState from './context/AuthenticatState';
import Compiler from './components/Pages/Compiler';
// import NotFound from './components/Pages/NotFound';
import ContestPage from './components/Pages/Contests-pages/ContestPage';
import QuizContestPage from './components/Pages/Contests-pages/QuizContestPage';
import CodingContestPage from './components/Pages/Contests-pages/CodingContestPage';
import Dashboard from './components/Pages/User-account/Dashboard';


function App() {
  
  
  return (
    <div>
      <AuthenticatState>
      <BrowserRouter>
        
          <Routes>
            <Route exact path='/' element={<Home />}  />
            <Route path='*' element={<Home />}/>

            <Route exact path='/dashboard/:userID' element={<Dashboard />} /> {/*For normal dashboard url*/}
            <Route  path='/dashboard/:username/:dashboardTag' element={<Dashboard />} /> For dynamic dashboard url

            <Route exact path='/courses' element={<Courses/>}   />

            <Route exact path='/mycourses' element={<MyCourses/>}   />

            <Route exact path='/user/login/:loginredirect' element={<Login mode="practcode-user"/>}   />

            <Route exact path='/user/signup' element={<Signup/>}   />

            <Route exact path='/hackfest2024-coding-competition' element={<Hackthon/>}   />

            <Route exact path='/hackfest2024/login' element={<Login mode="hackest2024-user" />}   />
            
            <Route exact path='user/login' element={<Login mode="userauth" />}   />

            <Route exact path='/compiler' element={<Compiler/>}   />

            <Route exact path='/contest/:contestName' element={<ContestPage />} />

            <Route exact path='/contest/quiz/:contestName' element={<QuizContestPage />} />

            <Route exact path='/contest/coding/:contestName' element={<CodingContestPage />} />

          </Routes>
        
      </BrowserRouter>
      </AuthenticatState>
    </div>
  );
}

export default App;
