import React, { useContext, useEffect, useState } from "react";
import '../../../Assets/style/User-account/dashboard.css'
import Navbar from '../../Navbar'
import { AuthenticatContext, UserAuthContext } from "../../../context/AuthenticatContext";
import { NavLink, Navigate,useParams } from "react-router-dom";

import Leaderboard from "../../Basic-components/dashboard/Leaderboard";
function Dashboard() {
    useEffect(() => {

        const handleKeyDown = (event) => {
          if (event.ctrlKey) {
            event.preventDefault(); // Prevent default behavior
            // Optionally, you can notify the user that Ctrl key is disabled.
            // alert('Ctrl key is disabled.');
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        // Cleanup function to remove the event listener when component unmounts
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []); // Empty dependency array ensures that the effect runs only once
      const { UserAuthdata, update_UserAuthData } = useContext(UserAuthContext)
    const { userID } = useParams();
   

    const { authentication_data, update_authentication } = useContext(AuthenticatContext);
    const sidebar = {
        "Leaderboard": "/leaderboard",
        "Progress": "/progress",
        "Contests": "/contests"
    }

   

        return (
            <div >
                <Navbar sidebar={sidebar} topbar={[]} />
                <div className="dashboard-container">
                    <div className="small-status-bar">
                        <p>{'Dashboard > Leaderboard'}</p>
                    </div>
                    <Leaderboard user= {UserAuthdata} />

                </div>
            </div>

        )
    
}

export default Dashboard